import * as React from 'react';
import cn from 'classnames';
import css from './FranchiseProducts.scss';
import Grid from 'styleguide/components/Grid/Grid';
import { H2 } from 'styleguide/components/Heading';
import AppContext from 'contexts/AppContext/AppContext';
import ProductCard from 'styleguide/components/NavigationCard/NavigationCard';

import marketing from 'assets/images/marketing.png';
import shipping from 'assets/images/shipping-boxes.png';

interface Props {
  permalinks: string[];
  heading?: string;
  nameOverrides?: string[];
  linkOverrides?: string[];
}

const FranchiseProducts = ({ permalinks, heading, nameOverrides, linkOverrides }: Props) => {
  const appContext = React.useContext(AppContext);
  const taxonomy = appContext.store.taxonomies.find(elem => elem.name === 'Products');
  const franchiseTaxons = taxonomy.taxons.filter(taxon => permalinks.indexOf(taxon.pagePath) > -1);

  const getProductImage = elem => {
    let image;
    if (elem.name === 'Rack Cards') {
      image = marketing;
    } else if (elem.name === 'Shipping Boxes') {
      image = shipping;
    } else {
      image = elem.image.url;
    }
    return image;
  };

  return (
    <Grid.Container>
      <Grid>
        {!!heading && (
          <Grid.Row>
            <Grid.Col md={1} hiddenSm="down" />
            <Grid.Col sm={12} md={10}>
              <H2 className={cn(css.Heading)}>{heading}</H2>
            </Grid.Col>
            <Grid.Col md={1} hiddenSm="down" />
          </Grid.Row>
        )}
        <Grid.Row className={css.productContainer}>
          <Grid.Col md={1} hiddenSm="down" />
          <Grid.Col sm={12} md={10} className={css.productListContainer}>
            {franchiseTaxons.map((elem, i) => (
              <ProductCard
                key={i}
                alt={elem.image.alt}
                image={getProductImage(elem)}
                title={!!nameOverrides && !!nameOverrides[i] ? nameOverrides[i] : elem.name}
                url={!!linkOverrides && !!linkOverrides[i] ? linkOverrides[i] : '#'}
                disabled={!(!!linkOverrides && !!linkOverrides[i])}
                className={css.productCard}
                titleClassName={css.productCardTitle}
                animateHover={false}
                dropShadow={false}
              />
            ))}
          </Grid.Col>
          <Grid.Col md={1} hiddenSm="down" />
        </Grid.Row>
      </Grid>
    </Grid.Container>
  );
};

export default FranchiseProducts;
