import * as React from 'react';
import css from './Brands.scss';
import Grid from 'styleguide/components/Grid/Grid';
import { H2, H3 } from 'styleguide/components/Heading';
import InfoCards from 'styleguide/components/InfoCards/InfoCards';
import { Info } from 'styleguide/components/InfoCards/InfoCard/types';

interface Props {
  cards: Info[];
  brandHeading?: string;
  brandTagline?: string;
}

const Brands = ({ cards, brandHeading, brandTagline }: Props) => (
  <>
    {!!brandHeading ||
      (!!brandTagline && (
        <Grid.Container>
          <Grid>
            <Grid.Row>
              <Grid.Col>
                {!!brandHeading && <H2 className={css.brandHeading}>{brandHeading}</H2>}
                {!!brandTagline && <H3 className={css.brandTagline}>{brandTagline}</H3>}
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </Grid.Container>
      ))}
    <InfoCards infoCards={cards} iconSize="md" />
  </>
);

export default Brands;
